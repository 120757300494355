import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { HeadingText } from "./heading-text"
import CommonSection from "./common-section"
import MonaImage from '../images/photo-mona.jpg'
import { css } from "@emotion/core"

const RegularText = styled("span")`
  ${tw`text-center block leading-normal text-main-text-small sm:text-main-text mb-5`};
`

const AboutSection = () => {
  return (
    <CommonSection blockId="about-section">
      <HeadingText>Über mich</HeadingText>
      <div tw="flex items-center flex-col px-5 sm:px-0" css={css`max-width: 580px; margin: 0 auto;`}>
        <RegularText>„Die Schönheit jedes Menschen zu betonen ist die schönste Aufgabe der Welt“
        </RegularText>
        <RegularText>In unserer schnelllebigen Zeit sehnen wir uns oft nach Momenten der Ruhe und Entspannung. 
          Gönnen Sie sich eine Auszeit vom Alltag und genießen Sie eine erholsame 
          Behandlung bei mir im Studio – schöpfen Sie neue Energie und lassen Sie sich rundum verwöhnen.
        </RegularText>
      </div>
      <div tw="flex items-center flex-col">
        <img src={MonaImage} alt="Mona" tw="mb-2 w-full sm:w-auto" css={css`max-width: 300px`} />
        <span css={css`font-family: "Brandon Grotesque Bold", serif;`}>Mona Zaugg</span>
        <span>Kosmetikerin EFZ, Inhaberin</span>
      </div>
    </CommonSection>
  )
}

export default AboutSection
