import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { HeadingText } from "./heading-text"
import CommonSection from "./common-section"
import Image from "./image"

const ImageWrapper = styled("span")`
  ${tw`flex justify-center w-1/2 lg:w-1/4 p-3`};
`

const ProductSection = () => {
  return (
    <CommonSection blockId="product-section">
      <HeadingText>Produkte</HeadingText>
      <div tw="flex flex-wrap">
        <ImageWrapper tw="flex justify-center w-1/2 lg:w-1/4 p-3">
          <Image filename="product/Product_1.jpg" alt="Collagen Booster"/>
        </ImageWrapper>
        <ImageWrapper tw="flex justify-center w-1/2 lg:w-1/4 p-3">
          <Image filename="product/Product_2.jpg" alt="Sensitive"/>
        </ImageWrapper>
        <ImageWrapper tw="flex justify-center w-1/2 lg:w-1/4 p-3">
          <Image filename="product/Product_3.jpg" alt="Kajal Eyeliner"/>
        </ImageWrapper>
        <ImageWrapper tw="flex justify-center w-1/2 lg:w-1/4 p-3">
          <Image filename="product/Product_4.jpg" alt="Lippenstifte"/>
        </ImageWrapper>
      </div>
    </CommonSection>
  )
}

export default ProductSection
