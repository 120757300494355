import React from "react"
import tw from "twin.macro"
import MaxWidthContainer from "./max-width-container"
import Logo2 from "../images/monessa-logo-2.png"

const CommonSection = ({blockId, children}) => {
  return (
    <section tw="my-20 lg:my-24 px-2 sm:px-0" id={blockId}>
      <MaxWidthContainer>
        <div tw="flex justify-center items-center">
          <img src={Logo2} alt="butterfly"/>
        </div>
        {children}
      </MaxWidthContainer>
    </section>
  )
}

export default CommonSection
