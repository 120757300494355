import React from "react"
import Layout from "../components/layout"
import Content from "../components/content"
import ContentWebsiteSleep from "../components/content-sleep"
import SEO from "../components/seo"

const IndexPage = () => { 
  const isWebsiteSleep = false;
  return (
    <Layout shortLayoutVersion={isWebsiteSleep}>
      <SEO title="Home" />
      {!isWebsiteSleep ? (
        <Content/>
      ) : (
        <ContentWebsiteSleep />
      )}
    </Layout>
  )
}

export default IndexPage
