import React from "react"
import styled from "@emotion/styled"
import tw, {theme} from "twin.macro"
import ServicePagesTemplate from "./service-pages-template"

const StyledHeadingText = styled("h2")`
  ${tw`uppercase text-center mb-8`};
  font-size: 30px;
  text-center;
  line-height: 42px;
  @media (max-width: 768px) {
      font-size: 22px;
      line-height: 31px;
  }
`

const SmallText = styled("span")`
  ${tw`text-center block`};
  font-size: 24px;
  line-height: 50px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 22px;
  }
`

const StyledLink = styled("a")`
  ${tw`cursor-pointer py-3 px-5 text-white hover:text-primary`};
  background: ${theme('colors.secondary-light')};
  margin-top: 20px;
  font-size: 18px;
  text-decoration: none;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
`

const ContentSleepPage = () => {
  return (
    <ServicePagesTemplate>
        {/* <StyledHeadingText>
            Liebe Kundinnen und Kunden, <br />
            Ab November 2020 werde ich meine Arbeit reduziert wieder aufnehmen. <br /> 
            Ich werde Sie Mitte Oktober genauer informieren und ab dann nehme ich gerne Ihren Termin entgegen.
            Ich freue mich auf Sie.
        </StyledHeadingText>
        <SmallText>Herzliche Grüsse, Mona Zaugg.</SmallText> */}
        {/* <StyledLink 
          href="/pdf/Kundenschreiben.pdf" 
          target="_blank">
            Kundeninformation
        </StyledLink> */}
        <StyledHeadingText>
            Liebe Kundinnen und Kunden, <br />
            Aufgrund meines Mutterschafturlaubes bleibt das Geschäft bis Januar 2022 geschlossen.<br /> 
            Nach dem Mutterschaftsurlaub freue ich mich wieder für Sie da zu sein.
        </StyledHeadingText>
        <SmallText>
            Herzlichen Dank für Ihr Verständnis<br />
            Mona Zaugg, Monessa Kosmetik
        </SmallText>
    </ServicePagesTemplate>
  )
}

export default ContentSleepPage
