import React from "react"
import tw from "twin.macro"
import styled from "@emotion/styled"

const HeadingTextStyled = styled.h2`
  ${tw`text-center text-main-heading-small sm:text-main-heading leading-tight text-gray my-10`};
  letter-spacing: 1px;
`

export const SubHeadingText = styled.h3`
  ${tw`text-center text-sub-heading-small sm:text-sub-heading uppercase leading-relaxed`};
  font-family: "Brandon Grotesque Bold", serif;
  color: inherit;
`

export const HeadingText = ({ children }) => (
  <div tw="flex justify-center">
    <HeadingTextStyled>
      {children}
    </HeadingTextStyled>
  </div>
)