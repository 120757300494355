import React from "react"
import ProductSection from './product-section'
import InfoSection from './info-section'
import OfferSection from './offer-section'
import SliderBlock from "./slider-block"
import AboutSection from "./about-section"

const Content = () => (
  <div tw="px-2 sm:px-3 pb-20 pt-5">
    <SliderBlock/>
    <OfferSection/>
    <ProductSection/>
    <AboutSection/>
    <InfoSection/>
  </div>
)

export default Content
