import React from "react"
import MaxWidthContainer from "./max-width-container"
import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from "./image"
// Import Swiper styles
// import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.min.css';
// import 'swiper/components/pagination/pagination.scss';

// install Swiper components
SwiperCore.use([Pagination, Autoplay, EffectFade]);

const SliderBlock = () => {
  return (
    <MaxWidthContainer>
      <Swiper
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 7000,
        }}
        effect="fade"
        fadeEffect={{
          crossFade: true
        }}
      >
        <SwiperSlide>
          <Image filename="slider/Studio_1.jpg" alt="Studio"/>
        </SwiperSlide>
        <SwiperSlide>
          <Image filename="slider/Studio_2.jpg" alt="Studio"/>
        </SwiperSlide>
        <SwiperSlide>
          <Image filename="slider/Studio_3.jpg" alt="Studio"/>
        </SwiperSlide>
      </Swiper>   
    </MaxWidthContainer>
  )
}

export default SliderBlock