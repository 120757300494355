import React, { useState } from "react"
import tw, { theme } from "twin.macro"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import CommonSection from "./common-section"
import PricesModal from "./prices-modal"
import { HeadingText } from "./heading-text"
import TriangleIcon from "../svg/triangle-icon.svg"
import ProductsPricesData from "../prices.json"

const OfferCard = ({borderColor, smOrder, onPriceButtonClick, cardContent, isModalWhiteTextColor}) => {
  const CardWrapper = styled("div")`
    ${tw`flex items-center justify-center p-1 sm:p-2 overflow-hidden w-full sm:w-1/2 lg:w-1/3`};
    @media (min-width: 640px) and (max-width: 1023px) { 
      order: ${smOrder};
    }
  `

  const CardStyled = styled("div")`
    ${tw`flex flex-col p-2 sm:p-5  items-center justify-center w-full h-full text-gray`};
    border: 10px solid ${borderColor};
    height: 236px;
  `

  const SubHeadingTextMedium = styled.h3`
    ${tw`text-center text-main-text sm:text-main-heading-small uppercase leading-relaxed`};
    font-family: "Brandon Grotesque Medium", serif;
    color: inherit;
  `

  const PriceText = styled.span`
    ${tw`flex hover:scale-125`};
    transition: all 300ms ease;
  `

  const PriceButton = styled.div`
    ${tw`flex justify-center items-center text-main-text cursor-pointer`};
    color: inherit; 
    padding: 5px 10px;
    &:hover {
      ${PriceText} {
        @media (min-width: 1281px) {
          color: ${theme('colors.primary')}
        }
      }
    }
  `

  return (
    <CardWrapper>
      <CardStyled>
        <div 
          tw="flex justify-center items-center flex-col w-full font-bold mb-3" 
          css={css`color: inherit`}
        >
          <SubHeadingTextMedium>
            { cardContent.category }
          </SubHeadingTextMedium>
        </div>
        <div 
          tw="flex justify-center items-center"  
        > 
          <PriceButton onClick={() => onPriceButtonClick(borderColor, cardContent, isModalWhiteTextColor)}>
            <PriceText>
              PREISE
            </PriceText>
            <TriangleIcon 
              tw="flex ml-3" 
              css={css`fill: inherit;`}
            />
          </PriceButton>
            
        </div>
      </CardStyled>
    </CardWrapper>
)}

const OfferSection = () => {
  const [modalBgColor, setModalBgColor] = useState(null);
  const [isModalTextWhite, setIsModalTextWhite] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const switchModal = (modalBgColor) => {
    setIsModalOpen(!isModalOpen);
    setModalBgColor(modalBgColor);
  }
  const onPriceButtonClick = (modalBgColor, content, isModalWhiteTextColor) => {
    setIsModalOpen(!isModalOpen);
    setModalBgColor(modalBgColor);
    setModalContent(content);
    setIsModalTextWhite(isModalWhiteTextColor);
  }
  return (
    <CommonSection blockId="offer-section">
      <HeadingText>
        Schönheits- und Körperpflege aller Art
      </HeadingText>
      <div tw="flex flex-wrap">
        { (ProductsPricesData && ProductsPricesData.length > 0) && ProductsPricesData.map((item, index) => (
            <OfferCard
              key={index}
              onPriceButtonClick={onPriceButtonClick}
              smOrder={item.smOrder} 
              borderColor={(index % 2 === 0) ? theme('colors.secondary-light') : theme('colors.primary-light')}
              isModalWhiteTextColor={(index % 2 !== 0)}
              cardContent={item}
            >
          </OfferCard>
        ))}
      </div>
      <PricesModal 
        switchModal={switchModal}
        isModalOpen={isModalOpen}
        bgColor={modalBgColor}
        isModalTextWhite={isModalTextWhite}
        modalContent={modalContent}
      />
    </CommonSection>
  )
}

export default OfferSection
