import React, { useState } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import CloseIcon from '../svg/close-icon.svg'
import { css } from "@emotion/core"
import ReactModal from 'react-modal'

const modalInlineStyles = (bgColor = '#FFFFFF')  => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 999,
  },
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    width: '93%',
    minWidth: '280px',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '30px',
    backgroundColor: bgColor,
    minHeight: '400px', 
    maxHeight: '90vh',
    boxShadow: 'rgba(0, 0, 0, 0.4) 3px 5px 15px 1px',
  }
})

const Heading = styled("h2")`
  font-family: "Brandon Grotesque Medium", serif;
  font-size: 22px;
  line-height: 24px;
  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 22px;
  }
`

const SubHeading = styled("h3")`
  font-size: 20px;
  line-height: 24px;
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 20px;
  }
`

const PricesModal = ({switchModal, isModalOpen, bgColor, modalContent, isModalTextWhite}) => {
  const [windowOffset, setWindowOffset] = useState(null);
  const onAfterModalOpen = () => {
    if(window !== undefined) {
      let offset = window.scrollY;
      setWindowOffset(offset);
      document.body.setAttribute('style', `position: fixed; top: -${offset}px; left: 0; right: 0`);
    }
  }
  const onAfterModalClose = () => {
    if(window !== undefined) {
      document.body.setAttribute('style', ' ');
      window.scrollTo(0, windowOffset)
    }
  }
  return (
    <ReactModal
        closeTimeoutMS={300}
        style={modalInlineStyles(bgColor)}
        isOpen={isModalOpen}
        onRequestClose={switchModal}
        contentLabel="Prices"
        portalClassName="modal"
        onAfterOpen={onAfterModalOpen}
        onAfterClose={onAfterModalClose}
        ariaHideApp={false}
      >
        <div tw="flex flex-wrap w-full mt-5">
          <Heading 
            tw="mb-4"
            css={css`
              ${isModalTextWhite ? (`color: #FFFFFF;`) : ('')
            }`}
          >{ modalContent.category }</Heading>
          { (modalContent.content && modalContent.content.length > 0) && modalContent.content.map((item, index) => (
            <React.Fragment key={index}>
              <div tw="w-full flex mb-2">
                <div css={css`
                  width: calc(100% - 100px);
                  @media (max-width: 480px) {
                    width: calc(100% - 80px);
                  }`  
                }>
                  <SubHeading css={css`
                    ${isModalTextWhite ? (`color: #FFFFFF;`) : ('')
                  }`}>
                    { item.name }
                  </SubHeading>
                </div>
                <div 
                  tw="flex justify-end sm:justify-center" 
                  css={css`
                    width: 100px;
                    @media (max-width: 480px) {
                      width: 80px;
                    }`
                  }>
                  <span css={css`
                    ${isModalTextWhite ? (`color: #FFFFFF;`) : ('')}
                    font-size: 19px; 
                    text-transform: uppercase;
                    @media (max-width: 480px) {
                      font-size: 16px;
                    }
                  `}>
                    CHF { item.price }.-
                  </span>
                </div>
              </div>
              {item.description && 
                <div css={css`width: calc(100% - 100px);`} tw="mb-8">
                  {item.description}
                </div>
              }  
            </React.Fragment>  
          ))}
        </div>
        <button 
          onClick={switchModal}
          tw="p-3 cursor-pointer absolute outline-none"
          css={css`
            ${isModalTextWhite ? (`:focus {outline-color: #FFFFFF;}`) : ('')}
            top: 10px; right: 10px;
          `}
        > 
          <CloseIcon 
            css={css`
              ${isModalTextWhite ? (`path {fill: #FFFFFF;}`) : ('')
            }`}
          />
        </button>
    </ReactModal>
  )
}

export default PricesModal