import React from "react"
import styled from "@emotion/styled"
import tw, {theme} from "twin.macro"
import { HeadingText, SubHeadingText } from "./heading-text"
import CommonSection from "./common-section"
import { css } from "@emotion/core"

const RegularText = styled("span")`
  ${tw`text-center block leading-relaxed text-sub-heading-small sm:text-sub-heading`};
`

const InfoSection = () => {
  return (
    <CommonSection blockId="info-section">
      <HeadingText>Kommen Sie vorbei</HeadingText>
      <div tw="flex items-center flex-col py-5 px-5" css={css`border: 6px solid ${theme('colors.primary-light')}`}>
        <div tw="py-5 mt-10">
          <SubHeadingText>Monessa Kosmetik</SubHeadingText>
          <RegularText>Hofstatt 5, 4522 Rüttenen</RegularText>
          <RegularText>info@monessakosmetik.ch / 032 622 77 55</RegularText>
        </div>
        <div tw="py-5 mb-10">
          <SubHeadingText>Öffnungszeiten</SubHeadingText>
          <RegularText>Donnerstag: 13.00–21.00</RegularText>
        </div>
      </div>
    </CommonSection>
  )
}

export default InfoSection
